import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import HeroHeader from "../components/HeroHeader"
import styled from "styled-components"

const FormContainer = styled.div`
  /* border: 2px solid black; */

  max-width: 800px;
  margin: 1rem auto;

  form {
    margin: 0 1.5rem;
  }

  h4 {
    margin: 0.3rem;
  }
  textarea {
    width: 100%;
    min-height: 150px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.3rem;
    margin: 0.3rem 0;
  }
`

const Input = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 0.3rem;
  margin: 0.3rem 0;
`

const StyledSelect = styled.select`
  width: 30%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 0.25rem;
  margin: 0.3rem 0;
`

const SubmitButton = styled.button`
  background-color: red;
  color: white;
  text-align: center;
  font-size: 1.2em;
  padding: 0.5rem;
  border: none;
  margin: 0.5rem 0;
`

const FormModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* z-index: 3; */
  /* padding: 1rem 0; */
`

const ResponseModal = styled.div`
  width: 310px;
  left: 50%;
  margin-left: -170px;
  padding: 1rem;
  position: fixed;
  background-color: white;
  border: 1px solid black;
  /* z-index: 3; */
  bottom: 400px;
  box-shadow: 5px 10px 10px #888;
  font-size: 1.3em;
`

const ModalBtn = styled.button`
  background-color: red;
  color: white;
  border: none;
  font-size: 0.8em;
  padding: 0.5rem;
  margin: 1rem 0 0 0;
`

const ContactPage = () => {
  const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
  const url = "https://www.tomcatequip.com/leadEP.php?"
  const geoUrl =
    "https://geolocation-db.com/json/dceecf90-43b2-11eb-b281-2f9a565e0770"

  const [ipDetails, setIpDetails] = useState({})

  async function getIP() {
    const response = await fetch(`${geoUrl}`)
    response.json().then(response => setIpDetails(response))
  }

  useEffect(() => {
    getIP()
  }, [])

  function handleFormDisplay() {
    if (formResponse.success) {
      window.location.reload()
      setViewModal("none")
    } else {
      setViewModal("none")
      setDisplayFormModal("none")
    }
  }

  // const [status, setStatus] = useState("")

  const [formData, setFormData] = useState({
    hcheck: "",
    origin: "FC_Website",
    company: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    fname: "",
    title: "",
    phone: "",
    email: "",
    comment: "",
    ip: "",
  })

  async function handleSubmit(e) {
    e.preventDefault()
    const response = await fetch(
      `${CORS_PROXY}${url}` +
        new URLSearchParams({
          hcheck: formData.hcheck,
          origin: formData.origin,
          company: formData.company,
          address: formData.address,
          address2: formData.address2,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          country: formData.country,
          fname: formData.fname,
          title: formData.title,
          phone: formData.phone,
          email: formData.email,
          ip: ipDetails.IPv4,
        })
    )
    response.json().then(response => setFormResponse(response))
    setViewModal("block")
    setDisplayFormModal("block")
  }

  const [formResponse, setFormResponse] = useState({})
  const [displayFormModal, setDisplayFormModal] = useState("none")
  const [viewModal, setViewModal] = useState("none")
  const [successMessage, setSuccessMessage] = useState(
    "Your request has been processed.  You should hear from a Factory Cat representative within the next 24 hours."
  )

  function handleChange(e) {
    const value = e.target.value
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
  }

  // function submitForm(ev) {
  //   ev.preventDefault()
  //   const form = ev.target
  //   const data = new FormData(form)
  //   const xhr = new XMLHttpRequest()
  //   xhr.open(form.method, form.action)
  //   xhr.setRequestHeader("Accept", "application/json")
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState !== XMLHttpRequest.DONE) return
  //     if (xhr.status === 200) {
  //       form.reset()
  //       setStatus("SUCCESS")
  //     } else {
  //       setStatus("ERROR")
  //     }
  //   }
  //   xhr.send(data)
  // }

  return (
    <Layout>
      <FormModal style={{ display: displayFormModal }}></FormModal>
        <Head title="contact" />

        {/* <HeroHeader /> */}
        <ResponseModal style={{ display: viewModal }}>
          {formResponse.success && successMessage}
          {formResponse.err && formResponse.err}
          <br />
          <ModalBtn onClick={handleFormDisplay}>Back to Form</ModalBtn>
        </ResponseModal>
        <FormContainer>
          <form onSubmit={handleSubmit} name="factoryCat-contact-form">
            <h2>Company Information</h2>
            <h4>Company Name: </h4>
            <Input
              onChange={handleChange}
              value={formData.company}
              name="company"
            />
            <h4>Address: </h4>
            <Input
              onChange={handleChange}
              value={formData.address}
              name="address"
              placeholder="Street"
            />
            <Input
              style={{ width: "65%", marginRight: "10px" }}
              onChange={handleChange}
              value={formData.city}
              name="city"
              placeholder="City"
            />
            <StyledSelect
              primary
              onChange={handleChange}
              name="state"
              value={formData.state}
            >
              <option value="Choose">Choose a State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </StyledSelect>
            <Input
              onChange={handleChange}
              value={formData.zip}
              style={{ width: "40%" }}
              name="zip"
              placeholder="Zip"
            />
            <h2>Primary Contact Information</h2>
            <h4>Full Name: </h4>
            <Input
              onChange={handleChange}
              value={formData.fname}
              name="fname"
            />
            <h4>Job Title: </h4>
            <Input
              onChange={handleChange}
              value={formData.title}
              name="title"
            />
            <h4>Contact Number: </h4>
            <Input
              onChange={handleChange}
              value={formData.phone}
              name="phone"
            />
            <h4>E-Mail: </h4>
            <Input
              onChange={handleChange}
              value={formData.email}
              type="email"
              name="email"
            />
            <h2>Additional Information</h2>
            <textarea
              onChange={handleChange}
              value={formData.comment}
              name="comment"
            />
            <h4>
              To validate this form please type the word "Human" into the field
              below
            </h4>
            <Input
              onChange={handleChange}
              style={{ width: "50%" }}
              value={formData.hcheck}
              name="hcheck"
            />
            <br />
            <SubmitButton type="submit">Submit</SubmitButton>
          </form>
        </FormContainer>
      </Layout>
  )
}

export default ContactPage
